<template>
  <basic-container>
    <head-layout :head-title="$t('cip.plat.authority.apiScope.title.indexHeadTitle')"></head-layout>
    <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-on="onSearchEvent">
    </grid-head-layout>
    <grid-layout ref="gridLayOut" :tableOptions="option" :tableData="data" :table-loading="loading"
                 @gird-handle-select-click="selectionChange"
                 :gridRowBtn="gridRowBtn"
                 @scope_confBtn="handleDataScope"
                 @tree-load='treeLoad'
                 :page="page"
                 @page-size-change="onLoad"
                 @page-current-change="onLoad"
                 @page-refresh-change="onLoad"
                ></grid-layout>
    <el-drawer  :visible.sync="drawerVisible"
                :direction="direction"
                append-to-body custom-class="wf-drawer"
                :before-close="handleDrawerClose"
                size="1000px">
      <basic-container>
        <head-layout head-title="接口权限配置"  :head-btn-options="headBtnOptions" @head-add="headAdd"></head-layout>
        <grid-head-layout ref="searchScopeFrom"
                          :search-columns="searchScopeColumns"
                          @grid-head-search="gridHeadSearch"
                          @grid-head-empty="gridHeadEmpty">
        </grid-head-layout>
        <grid-layout ref="crudScope"
                     :tableOptions="optionScope"
                     :tableData="dataScope"
                     :table-loading="scopeLoading"
                     :gridRowBtn="gridScopeRowBtn"
                     :page="pageScope"
                     @page-size-change="onLoadScope"
                     @page-current-change="onLoadScope"
                     @page-refresh-change="onLoadScope"

                     @row-del="rowDelScope"
                     @row-update="rowUpdateScope"
                     @row-save="rowSaveScope"
                     :before-open="beforeOpenScope"
                     @search-change="searchChangeScope"
                     @search-reset="searchResetScope"
                     @selection-change="selectionChangeScope"
                     @current-change="currentChangeScope"
                     @size-change="sizeChangeScope"
        ></grid-layout>
      </basic-container>
    </el-drawer>
    <CommonDialog v-if="showDialog" class="paperDialog" width="50%" dialogTitle="新增"
                  @confirm="rowSaveScope" @cancel="cancel">
      <form-layout ref="formLayout" :column="optionScope.column" :dataForm.sync="formScope"></form-layout>
    </CommonDialog>
  </basic-container>
</template>

<script>
  import {
    add,
    remove,
    update,
    getLazyMenuList,
    getMenu
  } from "@/api/system/menu";
  import {
    addApiScope,
    removeApiScope,
    updateApiScope,
    getListApiScope,
    getMenuApiScope
  } from "@/api/system/scope";
  import {mapGetters} from "vuex";
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import dialogHeadBtn from "@/views/components/layout/dialog-head-btn.vue";
  import CommonDialog from "@/components/CommonDialog";
  export default {
    components: {
      HeadLayout,
      GridLayout,
      dialogHeadBtn,
      CommonDialog
    },
    data() {
      return {
        showDialog:false,
        form: {},
        selectionList: [],
        query: {},
        loading: true,
        parentId: 0,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        drawerVisible: false,
        direction: 'rtl',
        scopeLoading: false,
        scopeMenuId: 0,
        scopeMenuName: "菜单",
        menu: true,
        searchColumns:[{
            prop: "name",
            search: true,
            placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.name"),
          },
          {
            placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.code"),
            prop: "code",
            search: true,
          }
        ],
        searchScopeColumns:[{
            prop: 'scopeName',
            search: true,
            placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.scopeName"),
          },
          {
            placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.resourceCode"),
            prop: "resourceCode",
            search: true,
          }
        ],
        option: {
          lazy: true,
          tip: false,
          simplePage: true,
          searchShow: false,
          searchMenuSpan: 6,
          dialogWidth: "60%",
          tree: true,
          border: true,
          index: true,
          selection: true,
          viewBtn: false,
          editBtn: false,
          addBtn: false,
          delBtn: false,
          menuWidth: 150,
          dialogClickModal: false,
          column: [
            {
              label:this.$t('cip.plat.authority.apiScope.field.name'),
              prop: "name",
              search: true,
              align: 'left',
              width: 300,
              overHidden: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.name"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t('cip.plat.authority.apiScope.field.path'),
              prop: "path",
              align: 'left',
              overHidden: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.path"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.parentIds"),
              prop: "parentId",
              type: "tree",
              dicUrl: "/api/sinoma-system/menu/tree",
              hide: true,
              align: 'right',
              width: 80,
              overHidden: true,
              props: {
                label: "title"
              },
              rules: [
                {
                  required: false,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.apiScope.field.parentIds"),
                  trigger: "click"
                }
              ]
            },

            {
              label: this.$t("cip.plat.authority.apiScope.field.code"),
              prop: "code",
              align: 'left',
              overHidden: true,
              search: true,
              width: 180,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.code"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.category"),
              prop: "category",
              type: "radio",
              overHidden: true,
              width: 140,
              align:'center',
              dicData: [
                {
                  label: this.$t("cip.plat.authority.apiScope.field.menu"),
                  value: 1
                },
                {
                  label: this.$t("cip.plat.authority.apiScope.field.button"),
                  value: 2
                }
              ],
              hide: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.apiScope.field.category"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.alias"),
              prop: "alias",
              overHidden: true,
              width: 140,
              align:'left',
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.alias"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.action"),
              prop: "action",
              type: "radio",
              overHidden: true,
              width: 140,
              align:'center',
              dicData: [
                {
                  label: this.$t("cip.plat.authority.apiScope.field.toolBar"),
                  value: 0
                },
                {
                  label: this.$t("cip.plat.authority.apiScope.field.actionBar"),
                  value: 1
                },
                {
                  label: this.$t("cip.plat.authority.apiScope.field.toolActionBar"),
                  value: 2
                }
              ],
              hide: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.apiScope.field.action"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.sorts"),
              prop: "sort",
              type: "number",
              minRows: 0,
              maxRows: 1000000000,
              align:'right',
              width: 80,
              overHidden: true,
              rules: [
                {
                  required: true,
                  message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.sorts"),
                  trigger: "blur"
                }
              ]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.isOpen"),
              prop: "isOpen",
              type: "radio",
              align:'right',
              width: 80,
              overHidden: true,
              dicData: [
                {
                  label: this.$t("cip.plat.authority.apiScope.field.no"),
                  value: 0
                },
                {
                  label: this.$t("cip.plat.authority.apiScope.field.yes"),
                  value: 1
                },
              ],
              hide: true
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.remarks"),
              prop: "remark",
              type: "textarea",
              align:'right',
              width: 80,
              overHidden: true,
              span: 24,
              minRows: 6,
              hide: true
            }
          ]
        },
        data: [],
        formScope: {},
        selectionListScope: [],
        pageScope: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        optionScope: {
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          viewBtn: false,
          selection: false,
          menuWidth: 200,
          dialogWidth: 900,
          dialogClickModal: false,
          column: [
            {
              label: this.$t("cip.plat.authority.apiScope.field.scopeName"),
              prop: "scopeName",
              maxlength: 200,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.scopeName"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.resourceCode"),
              prop: "resourceCode",
              maxlength: 200,
              width: 180,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.scopeName"),
                trigger: "blur"
              }]
            },
            {
              label: this.$t("cip.plat.authority.apiScope.field.scopePath"),
              prop: "scopePath",
              maxlength: 200,
              width: 180,
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.authority.apiScope.field.scopePath"),
                trigger: "blur"
              }]
            },
           /* {
              label: this.$t("cip.plat.authority.apiScope.field.scopeType"),
              type: "select",
              dicUrl: "/api/sinoma-system/dict/dictionary?code=api_scope_type",
              props: {
                label: "dictValue",
                value: "dictKey"
              },
              dataType: "number",
              slot: true,
              width: 100,
              prop: "scopeType",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.authority.apiScope.field.scopeType"),
                trigger: "blur"
              }]
            },*/
            {
              label: this.$t("cip.plat.authority.apiScope.field.remark"),
              prop: "remark",
              span: 24,
              maxlength: 200,
              hide: true,
            },
          ]
        },
        dataScope: []
      };
    },

    computed: {
      ...mapGetters(["permission"]),
      gridRowBtn() {
        let   buttonBtn = [];
        buttonBtn.push(
            {
              label:"权限配置",
              emit: "scope_confBtn",
              type: "text",
              icon: ""
            });
        return buttonBtn;
      },
      headBtnOptions() {
        return [
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            btnOptType: "add",
          }
        ];
      },

      gridScopeRowBtn() {
        let   buttonBtn = [];
        buttonBtn.push(
            {
              label:"编辑",
              emit: "row-update",
              type: "text",
              icon: ""
            });
        buttonBtn.push(
            {
              label:"删除",
              emit: "row-del",
              type: "text",
              icon: ""
            });
        return buttonBtn;
      },
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.menu_add, false),
          viewBtn: this.vaildData(this.permission.menu_view, false),
          delBtn: this.vaildData(this.permission.menu_delete, false),
          editBtn: this.vaildData(this.permission.menu_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },
      scopeIds() {
        let ids = [];
        this.selectionListScope.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },
      onSearchEvent() {
        return {
          'grid-head-search': this.searchChange,
          'grid-head-empty': this.searchReset
        }
      }
    },
    mounted(){
      this.onLoad(this.page)
    },
    methods: {
      // 菜单管理模块
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          done();
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      rowDel(row) {
        this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          });
      },
      searchReset() {
        this.query = {};
        this.parentId = 0;
        this.onLoad(this.page);
      },
      searchChange(params) {
        this.query = params;
        this.parentId = '';
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
      },
      selectionChange(list) {
        this.selectionList = list;
      },
      selectionClear() {
        this.selectionList = [];
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
          return;
        }
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getMenu(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      currentChange(currentPage) {
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize) {
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getLazyMenuList(this.parentId, Object.assign(params, this.query)).then(res => {
          console.log("res",res)
          this.data = res.data.data;
          this.loading = false;
          this.selectionClear();
        });
      },
      treeLoad(tree, treeNode, resolve) {

        const parentId = tree.id;
        getLazyMenuList(parentId).then(res => {
          console.log("res",res)
          resolve(res.data.data);
        });
      },
      // 数据权限模块
      handleDataScope(row) {
        this.drawerVisible = true;
        this.scopeMenuId = row.id;
        this.scopeMenuName = row.name;
        this.onLoadScope(this.pageScope)
      },
      handleDrawerClose() {
        this.drawerVisible=false
      },
      rowSaveScope() {
        this.formScope = {
          ...this.formScope,
          menuId: this.scopeMenuId,
        };
        updateApiScope(this.formScope).then(() => {
          this.onLoadScope(this.pageScope);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
        this.showDialog = false
      },
      cancel(){
        this.showDialog = false
      },
      rowUpdateScope(row) {
        this.showDialog = true
        this.formScope = row
      },
      rowDelScope(row) {
        this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return removeApiScope(row.id);
          })
          .then(() => {
            this.onLoadScope(this.pageScope);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          });
      },
      handleDeleteScope() {
        if (this.selectionListScope.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
          return;
        }
        this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
          confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
          cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
          type: "warning"
        })
          .then(() => {
            return removeApiScope(this.scopeIds);
          })
          .then(() => {
            this.onLoadScope(this.pageScope);
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.$refs.crudScope.toggleSelection();
          });
      },
      beforeOpenScope(done, type) {
        if (["edit", "view"].includes(type)) {
          getMenuApiScope(this.formScope.id).then(res => {
            this.formScope = res.data.data;
          });
        }
        done();
      },
      searchResetScope() {
        this.onLoadScope(this.pageScope);
      },
      searchChangeScope(params) {
        this.onLoadScope(this.pageScope, params);
      },
      selectionChangeScope(list) {
        this.selectionListScope = list;
      },
      currentChangeScope(currentPage) {
        this.pageScope.currentPage = currentPage;
      },
      sizeChangeScope(pageSize) {
        this.pageScope.pageSize = pageSize;
      },
      // 列表查询 add by steve
      gridHeadSearch(searchForm) {
        this.pageScope.currentPage = 1;
        this.onLoadScope(this.pageScope, searchForm);
      },
      // 查询重置 add by steve
      gridHeadEmpty(searchForm) {
        this.pageScope.currentPage = 1;
        this.onLoadScope(this.pageScope, searchForm);
      },
      headAdd() {
        this.showDialog = true;
      },
      onLoadScope(page, params = {}) {
      this.pageScope = page;
      this.scopeLoading = true;
      const values = {
        ...params,
        menuId: this.scopeMenuId,
      }
      getListApiScope(
        page.currentPage,
        page.pageSize,
        Object.assign(values, this.query)
      ).then(res => {
        console.log("res".res)
        const data = res.data.data;
        this.dataScope = data.records;
        this.selectionListScope = [];
        this.$refs.gridLayOut.grid.refreshTable()
        this.scopeLoading = false;
      });
    },
    }
  };
</script>
<style lang="scss">
.wf-drawer {
  .el-drawer__header {
    padding: 10px !important;
    color: #333333;
    font-size: 25px;
    margin: 10px 0px 0px !important;
  }
  .el-drawer__body {
    padding: 10px !important;
    overflow: auto;
  }
}
</style>
