var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.$t(
            "cip.plat.authority.apiScope.title.indexHeadTitle"
          ),
        },
      }),
      _c(
        "grid-head-layout",
        _vm._g(
          { ref: "searchFrom", attrs: { "search-columns": _vm.searchColumns } },
          _vm.onSearchEvent
        )
      ),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.option,
          tableData: _vm.data,
          "table-loading": _vm.loading,
          gridRowBtn: _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          scope_confBtn: _vm.handleDataScope,
          "tree-load": _vm.treeLoad,
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.drawerVisible,
            direction: _vm.direction,
            "append-to-body": "",
            "custom-class": "wf-drawer",
            "before-close": _vm.handleDrawerClose,
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisible = $event
            },
          },
        },
        [
          _c(
            "basic-container",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "接口权限配置",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: { "head-add": _vm.headAdd },
              }),
              _c("grid-head-layout", {
                ref: "searchScopeFrom",
                attrs: { "search-columns": _vm.searchScopeColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "crudScope",
                attrs: {
                  tableOptions: _vm.optionScope,
                  tableData: _vm.dataScope,
                  "table-loading": _vm.scopeLoading,
                  gridRowBtn: _vm.gridScopeRowBtn,
                  page: _vm.pageScope,
                  "before-open": _vm.beforeOpenScope,
                },
                on: {
                  "page-size-change": _vm.onLoadScope,
                  "page-current-change": _vm.onLoadScope,
                  "page-refresh-change": _vm.onLoadScope,
                  "row-del": _vm.rowDelScope,
                  "row-update": _vm.rowUpdateScope,
                  "row-save": _vm.rowSaveScope,
                  "search-change": _vm.searchChangeScope,
                  "search-reset": _vm.searchResetScope,
                  "selection-change": _vm.selectionChangeScope,
                  "current-change": _vm.currentChangeScope,
                  "size-change": _vm.sizeChangeScope,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDialog
        ? _c(
            "CommonDialog",
            {
              staticClass: "paperDialog",
              attrs: { width: "50%", dialogTitle: "新增" },
              on: { confirm: _vm.rowSaveScope, cancel: _vm.cancel },
            },
            [
              _c("form-layout", {
                ref: "formLayout",
                attrs: {
                  column: _vm.optionScope.column,
                  dataForm: _vm.formScope,
                },
                on: {
                  "update:dataForm": function ($event) {
                    _vm.formScope = $event
                  },
                  "update:data-form": function ($event) {
                    _vm.formScope = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }